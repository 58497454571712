import { DateUtils, formatToYear } from "../../../../classes/date.utils";
import { onNextFrame } from "../../../../classes/render-util";
export class InputController {
    constructor(minValue, maxValue, disabled, formatter, maxCharacters) {
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.disabled = disabled;
        this.formatter = formatter;
        this.maxCharacters = maxCharacters;
        this._inputElement = null;
        this.lastValue = "";
        this.valueBeforeFocus = "";
        this.focusHandle = () => this.onFocus();
        this.blurHandle = () => this.onBlur();
        this.inputHandle = (e) => this.onInput(e);
    }
    get inputElement() {
        return this._inputElement;
    }
    focus(focusOptions) {
        var _a, _b;
        this.lastValue = (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value;
        this.valueBeforeFocus = this.lastValue;
        (_b = this._inputElement) === null || _b === void 0 ? void 0 : _b.focus(focusOptions);
    }
    setValue(value, forceUpdate = false) {
        this.internalSetValue(value, forceUpdate);
    }
    sendInput(input) {
        var _a;
        // Does not set lastValue because input event is dispatched
        this.setInputElementValue(input);
        const evt = new Event("input", {
            bubbles: true,
            cancelable: true,
        });
        evt["data"] = input;
        evt["insertType"] = "insertText";
        (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(evt);
    }
    preventInput(input, inputType) {
        if (/delete|history/.test(inputType)) {
            return false;
        }
        // "." doesn't do anything before the user didn't make his first input
        if (input === ".") {
            if (this.lastValue !== this.valueBeforeFocus) {
                this.finish();
            }
            return true;
        }
        const inputNumber = Number(input);
        if (isNaN(inputNumber)) {
            return true;
        }
    }
    onInput(e) {
        e.preventDefault();
        const currentValue = this._inputElement.value;
        if (this.preventInput(e.data, e.inputType)) {
            // In case there was no input we want to keep the previous selection
            if (this.lastValue === this.valueBeforeFocus) {
                requestAnimationFrame(() => {
                    this._inputElement.select();
                });
            }
            this.updateValue(this.lastValue);
            return;
        }
        const newValue = currentValue.substring(0, this.maxCharacters);
        this.updateValue(newValue);
        if (newValue.length === this.maxCharacters) {
            this.finish();
        }
    }
    get value() {
        var _a;
        return parseFloat((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value);
    }
    get formattedValue() {
        var _a, _b;
        return (_b = this.formatter((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value)) !== null && _b !== void 0 ? _b : "";
    }
    getDisabled() {
        return this.disabled;
    }
    setDisabled(disabled) {
        this.disabled = !!disabled;
    }
    isEmpty() {
        var _a, _b, _c;
        return this.disabled || ((_c = (_b = (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) === 0;
    }
    clearInput() {
        if (!this.disabled && this._inputElement != null) {
            this.updateValue(null);
        }
    }
    onFocus() {
        var _a;
        if (this._inputElement.value.length > 0) {
            this.focus();
            this._inputElement.select();
        }
        (_a = this.onInputFocused) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    onBlur() {
        var _a;
        this.valueBeforeFocus = this.lastValue;
        this.format();
        (_a = this.onInputBlurred) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    setInputElement(el) {
        var _a, _b, _c, _d, _e, _f;
        if (this._inputElement !== el) {
            (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.removeEventListener("focus", this.focusHandle);
            (_b = this._inputElement) === null || _b === void 0 ? void 0 : _b.removeEventListener("blur", this.blurHandle);
            (_c = this._inputElement) === null || _c === void 0 ? void 0 : _c.removeEventListener("input", this.inputHandle);
            this._inputElement = el;
            (_d = this._inputElement) === null || _d === void 0 ? void 0 : _d.addEventListener("focus", this.focusHandle);
            (_e = this._inputElement) === null || _e === void 0 ? void 0 : _e.addEventListener("blur", this.blurHandle);
            (_f = this._inputElement) === null || _f === void 0 ? void 0 : _f.addEventListener("input", this.inputHandle);
            if (this._inputElement) {
                this._inputElement["i_controller"] = this;
            }
            onNextFrame(() => {
                this.format();
            });
        }
    }
    incrementValue() {
        const currentValue = this._inputElement.value;
        const newValue = currentValue === ""
            ? this.minValue
            : Math.min(this.maxValue, parseInt(this._inputElement.value)) + 1;
        this.internalSetValue(Math.max(this.minValue, newValue % (this.maxValue + 1)));
        requestAnimationFrame(() => {
            this._inputElement.select();
        });
    }
    decrementValue() {
        const currentValue = this._inputElement.value;
        const newValue = currentValue === ""
            ? this.maxValue
            : Math.min(this.maxValue, parseInt(this._inputElement.value) - 1);
        const lessThanMin = newValue - this.minValue < 0;
        const newComputedValue = lessThanMin
            ? this.maxValue + (newValue - this.minValue) + 1
            : newValue % (this.maxValue + 1);
        this.internalSetValue(newComputedValue);
        requestAnimationFrame(() => {
            this._inputElement.select();
        });
    }
    setInputElementValue(value) {
        if (this._inputElement != null) {
            this._inputElement.value = value;
        }
    }
    internalSetValue(value, emitValueChange = true) {
        const valueAsNumber = parseFloat(value === null || value === void 0 ? void 0 : value.toString());
        if (value != null && (valueAsNumber < this.minValue || valueAsNumber > this.maxValue)) {
            console.warn(`This segment can only be specified with a number between ${this.minValue} and ${this.maxValue} or null to empty the segment.`, this.inputElement);
            return;
        }
        if (this.disabled) {
            value = DateUtils.withMultipleLeadingZeros(this.minValue, 4);
        }
        this.updateValue(value == null || isNaN(+value) ? "" : value.toString(), emitValueChange);
        this.format(emitValueChange);
    }
    updateValue(value, emitValueChange = true) {
        var _a;
        const sameValue = this.lastValue === value;
        this.setInputElementValue(value);
        this.lastValue = value;
        if (!sameValue && emitValueChange) {
            (_a = this.onValueChanged) === null || _a === void 0 ? void 0 : _a.call(this, value);
        }
    }
    finish() {
        var _a;
        (_a = this.onFinished) === null || _a === void 0 ? void 0 : _a.call(this);
        this.format();
    }
    format(emitValueChange = true) {
        var _a, _b, _c;
        const currentValue = parseInt((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value);
        if (isNaN(currentValue)) {
            this.updateValue("", emitValueChange);
            return;
        }
        this.updateValue((_b = this.formatter) === null || _b === void 0 ? void 0 : _b.call(this, (_c = this._inputElement) === null || _c === void 0 ? void 0 : _c.value), emitValueChange);
    }
}
export function getDayInputController(disabled) {
    return new InputController(1, 31, disabled, formatWithLeadingZero, 2);
}
export function getMonthInputController(disabled) {
    return new InputController(1, 12, disabled, formatWithLeadingZero, 2);
}
export function getYearInputController(disabled) {
    return new InputController(0, 9999, disabled, formatToYear, 4);
}
export function getHourInputController(disabled) {
    return new InputController(0, 23, disabled, formatWithLeadingZero, 2);
}
export function getMinuteInputController(disabled) {
    return new InputController(0, 59, disabled, formatWithLeadingZero, 2);
}
export function formatWithLeadingZero(value) {
    if (!value) {
        return value;
    }
    return DateUtils.withLeadingZero(parseInt(value));
}
export function extractDateValuesFromInputControllers(yearInputController, monthInputController, dayInputController) {
    const day = !dayInputController.getDisabled() ? dayInputController.formattedValue : "01";
    const month = !monthInputController.getDisabled() ? monthInputController.formattedValue : "01";
    const year = !yearInputController.getDisabled() ? yearInputController.formattedValue : "0000";
    // We have to check every input because 'YYYY-MM-' is a valid date for chrome. Firefox is invalid in this case.
    if (day !== "" && month !== "" && year !== "") {
        return formatToDateString(`${year}-${month}-${day}`);
    }
    return null;
}
export function extractTimeValuesFromInputControllers(hourInputController, minuteInputController) {
    const hour = !hourInputController.getDisabled() ? hourInputController.formattedValue : "00";
    const minute = !minuteInputController.getDisabled() ? minuteInputController.formattedValue : "00";
    // We have to check every input because 'YYYY-MM-' is a valid date for chrome. Firefox is invalid in this case.
    if (hour !== "" && minute !== "") {
        return formatToTimeString(`${hour}:${minute}`);
    }
    return null;
}
export function formatToDateString(value) {
    if (value == null) {
        return null;
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) {
        return null;
    }
    else {
        // Chrome translates "2021-31-02 into 02.03.2021 instead of returning an "Invalid Date"
        const splittedDate = value.split("-").map((val) => parseFloat(val));
        if ((splittedDate[2] != null && splittedDate[2] !== date.getDate()) ||
            (splittedDate[1] != null && splittedDate[1] !== date.getMonth() + 1) ||
            (splittedDate[0] != null && splittedDate[0] !== date.getFullYear())) {
            return null;
        }
        return DateUtils.dateToString(date);
    }
}
export function formatToTimeString(value) {
    if (value == null) {
        return null;
    }
    const date = new Date(`0000-01-01T${value}`);
    const splittedTime = value.split(":").map((val) => parseFloat(val));
    if ((splittedTime[0] != null && splittedTime[0] !== date.getHours()) ||
        (splittedTime[1] != null && splittedTime[1] !== date.getMinutes())) {
        return null;
    }
    return value;
}
